import { Controller } from 'stimulus'
import Highmaps from 'highcharts/highmaps'
import { createBrowserHistory } from "history"

export default class extends Controller {
  static values = {
    nav: Boolean,
    captionText: String,

    selectedSize: String,
    selectedSizeName: String,

    selectedYear: String,

    fmrTypeRadioName: String,

    multipliers: Array,

    selectedTown: String,
    fmrTabId: String,
    fmrFilledMapData: Array,

    selectedZipCode: String,
    safmrTabId: String,
    safmrFilledMapData: Array,

    msaMapData: Array,

    communitiesMapData: Array,

    townLayerToggleId: String,
    msaLayerToggleId: String,
    communitiesLayerToggleId: String,

    selectedValueUrlClass: String,
  }
  static targets = ["map"]

  initialize() {
    this.radioSelector = `input:radio[name="${this.fmrTypeRadioNameValue}"]`
    this.$layerToggle = {
      'TOWN': $(`#${this.townLayerToggleIdValue}`),
      'MSA': $(`#${this.msaLayerToggleIdValue}`),
      'COMMUNITIES': $(`#${this.communitiesLayerToggleIdValue}`),
    }

    this.history = createBrowserHistory();

    this.initializeSeries()
    this.initializeTabs()
    this.initializeEvents()

    this.renderContent()

    this.toggleFmrType()

    this.fmrSortUrlName = `${this.fmrTabIdValue}_sort`.replaceAll('-', '_')
    this.safmrSortUrlName = `${this.safmrTabIdValue}_sort`.replaceAll('-', '_')
    let searchParams = new URLSearchParams(window.location.search)
    if (searchParams.get(this.fmrSortUrlName) || searchParams.get(this.safmrSortUrlName)) {
      this.fmrConf[this.fmrType]['$tab'].click()
    }
    $('#frms110-content').show()
  }

  initializeSeries() {
    const selectedZipCodeValue = this.selectedZipCodeValue || null
    const selectedTownValue = this.selectedTownValue || null
    this.series = []
    this.seriesConf = {
      'FMR': { 'series': this.fmr(), 'selected': selectedTownValue },
      'SAFMR': { 'series': this.safmr(), 'selected': selectedZipCodeValue },
      'TOWN': { 'series': this.townOutline() },
      'MSA': { 'series': this.msaOutline() },
      'COMMUNITIES': { 'series': this.communitiesOutline() },
    }
    for (const [index, [key, value]] of Object.entries(Object.entries(this.seriesConf))) {
      value['index'] = index
      this.series.push(value['series'])
    }
  }

  getSelectedValue(name) {
    return this.seriesConf[name]['selected']
  }

  getSeriesIndex(name) {
    return this.seriesConf[name]['index']
  }

  getSeries(name) {
    return this.map.series[this.getSeriesIndex(name)]
  }

  toggleMapSeries(enabledIndexes, disabledIndexes) {
    disabledIndexes.forEach((index) => { this.map.series[index].setVisible(false, false) })
    enabledIndexes.forEach((index) => { this.map.series[index].setVisible(true, false) })
    Object.keys(this.$layerToggle).forEach(key => {
      this.getSeries(key).setVisible(this.$layerToggle[key].prop('checked'), false)
    });
    this.map.redraw(false)
    this.updateUrl()
  }

  updateUrl() {
    let searchParams = new URLSearchParams(window.location.search)
    // Update fmr_type
    searchParams.delete('search[fmr_type]')
    searchParams.append('search[fmr_type]', this.fmrType)
    // Update selected_layers
    searchParams.delete('search[selected_layers][]')
    searchParams.append('search[selected_layers][]', '')

    Object.keys(this.$layerToggle).forEach(key => {
      if (this.$layerToggle[key].prop('checked')) {
        searchParams.append('search[selected_layers][]', key)
      }
    });
    // Rewrite url
    var url = `?${searchParams.toString()}`
    this.history.replace(url)
  }

  initializeTabs() {
    this.fmrConf = {
      'FMR': {
        '$tab': $(`#${this.fmrTabIdValue}`),
        'mapSeries': [this.getSeriesIndex('FMR')],
        'locationLabel': 'Town',
        'relatedLocationsLabel': 'Zip Codes',
      },
      'SAFMR': {
        '$tab': $(`#${this.safmrTabIdValue}`),
        'mapSeries': [this.getSeriesIndex('SAFMR')],
        'locationLabel': 'Zip Code',
        'relatedLocationsLabel': 'Towns',
      }
    }
  }

  toggleTableTab($enableTab, $disableTab) {
    $enableTab.show()
    if ($disableTab.hasClass('active')) { $enableTab.click() }
    $disableTab.hide()
  }

  toggleFmrType() {
    let fmrType = $(`${this.radioSelector}:checked`).val()
    this.fmrType = fmrType
    let disabledFmrType = Object.keys(this.fmrConf).filter(function (element) { return element != fmrType })
    this.toggleTableTab(this.fmrConf[fmrType]['$tab'], this.fmrConf[disabledFmrType]['$tab'])
    this.toggleMapSeries(this.fmrConf[fmrType]['mapSeries'], this.fmrConf[disabledFmrType]['mapSeries'])
    let title = `${this.selectedSizeNameValue} ${fmrType} for ${this.selectedYearValue}`;
    this.map.setTitle({ text: title });
  }

  initializeEvents() {
    const controller = this
    Object.keys(this.$layerToggle).forEach(key => {
      this.$layerToggle[key].change((event) => this.toggleFmrType())
    })
    $(this.radioSelector).change((event) => this.toggleFmrType())
    $(`.${this.selectedValueUrlClassValue}`).click((event) => {
      event.preventDefault()
      let searchParams = new URLSearchParams(window.location.search)
      let value = $(event.target).data('selected-value')
      let name = $(event.target).data('selected-name')
      searchParams.delete(controller.fmrSortUrlName)
      searchParams.delete(controller.safmrSortUrlName)
      searchParams.delete(name)
      searchParams.append(name, value)
      location.href = `?${searchParams.toString()}`
    })
    $(".sortable-header").click((event) => {
      event.preventDefault()
      let searchParams = new URLSearchParams(window.location.search)
      let fmrValue = $(event.target).data(controller.fmrSortUrlName.replaceAll('_', '-'))
      let safmrValue = $(event.target).data(controller.safmrSortUrlName.replaceAll('_', '-'))
      searchParams.delete(controller.fmrSortUrlName)
      searchParams.delete(controller.safmrSortUrlName)
      searchParams.append(controller.fmrSortUrlName, fmrValue)
      searchParams.append(controller.safmrSortUrlName, safmrValue)
      location.href = `?${searchParams.toString()}`
    })
  }

  formatterFunction(point) {
    if (point.series.name == 'MSA') {
      return false
    }

    // Depending on how many alt_names split it and change formatting
    const relatedLocationsLabel = this.fmrConf[this.fmrType]['relatedLocationsLabel']
    let relatedLocations = []
    if (point.alt_names) {
      let alt_names_array = point.alt_names.split(", ")
      const chunkSize = 5;

      if (alt_names_array.length <= 5) {
        relatedLocations.push(`${relatedLocationsLabel}: <b>${point.alt_names}</b>`)
      } else {
        relatedLocations.push(`${relatedLocationsLabel}:`)
        for (let i = 0; i < alt_names_array.length; i += chunkSize) {
          const chunk = alt_names_array.slice(i, i + chunkSize)
          relatedLocations.push(chunk.join(", "))
        }
      }
    } else {
      relatedLocations.push(`${relatedLocationsLabel}: Data unavailable`)
    }
    let elements = []
    elements.push(`${this.fmrConf[this.fmrType]['locationLabel']}: <b>${point.name}</b>`)
    if (this.fmrType == 'SAFMR') {
      elements = elements.concat(relatedLocations)
    }

    if (point.metro_area_name) {
      elements.push(`MSA: <b>${point.metro_area_name}</b>`)
    }

    elements.push(`Size: ${this.selectedSizeNameValue}`)
    elements.push(`Year: ${this.selectedYearValue}`)
    elements.push('<br />')
    this.multipliersValue.forEach((multiplier) => {
      const label = `${Math.trunc(multiplier * 100)}% ${this.fmrType}`
      const value = point.value > 0 ? `$${Math.trunc(multiplier * point.value).toLocaleString()}` : 'Data unavailable'
      elements.push(`<span>${label}: <b>${value}</b></span>`)
    })
    elements.push('<br />')


    if (this.fmrType == 'FMR') {
      elements = elements.concat(relatedLocations)
    }

    return elements.join('<br />')
  }
  safmr() {
    const controller = this
    return {
      zIndex: 0,
      data: this.safmrFilledMapDataValue,
      allowPointSelect: true,
      selectedSize: this.selectedSizeValue,
      selectedSizeName: this.selectedSizeNameValue,
      dataLabels: {
        enabled: true,
        formatter: function () {
          if (this.point.name == controller.getSelectedValue('SAFMR')) {
            return this.point.name
          }
        },
      },
      states: {
        hover: {
          borderColor: '#000',
          brightness: 0,
        },
        inactive: {
          opacity: 1
        },
        select: {
          color: '#f00',
        },
      },
    }
  }

  fmr() {
    const controller = this
    return {
      zIndex: 0,
      data: this.fmrFilledMapDataValue,
      allowPointSelect: true,
      selectedSize: this.selectedSizeValue,
      selectedSizeName: this.selectedSizeNameValue,
      dataLabels: {
        enabled: true,
        formatter: function () {
          if (this.point.name == controller.getSelectedValue('FMR')) {
            return this.point.name
          }
        },
      },
      states: {
        hover: {
          borderColor: '#000',
          brightness: 0,
        },
        inactive: {
          opacity: 1
        },
        select: {
          color: '#f00',
        },
      },
    }
  }

  msaOutline() {
    return {
      showInLegend: false,
      colorAxis: false,
      color: '#000',
      lineWidth: 2,
      zIndex: 2,
      type: 'mapline',
      name: 'MSA',
      data: this.msaMapDataValue,
      dataLabels: {
        enabled: true,
        color: 'rgba(0, 0, 0, 1)',
        borderRadius: 5,
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
        formatter: function () {
          return this.point.name
        },
      },
      states: {
        hover: {
          borderColor: '#f00',
          color: '#f00',
        },
        inactive: {
          opacity: 1
        },
        select: {
          borderColor: '#f00',
          color: '#f00',
        },
      },
    }
  }

  communitiesOutline() {
    return {
      showInLegend: false,
      colorAxis: false,
      color: '#808080',
      lineWidth: 1,
      zIndex: 1,
      type: 'mapline',
      name: 'COMMUNITIES',
      data: this.communitiesMapDataValue,
      dataLabels: {
        enabled: false,
      },
      states: {
        hover: {
          borderColor: '#f00',
          color: '#f00',
        },
        inactive: {
          opacity: 1
        },
        select: {
          borderColor: '#f00',
          color: '#f00',
        },
      },
    }
  }

  townOutline() {
    return {
      showInLegend: false,
      colorAxis: false,
      color: '#808080',
      lineWidth: 1,
      zIndex: 1,
      type: 'mapline',
      name: 'MSA',
      data: this.fmrFilledMapDataValue,
      dataLabels: {
        enabled: false,
      },
      states: {
        hover: {
          borderColor: '#f00',
          color: '#f00',
        },
        inactive: {
          opacity: 1
        },
        select: {
          borderColor: '#f00',
          color: '#f00',
        },
      },
    }
  }

  highlightSelectedValue(layerName) {
    const selectedValue = this.getSelectedValue(layerName) || null
    if (selectedValue) {
      const selectedLayer = this.getSeries(layerName).points.filter(function (point) {
        return point.name == selectedValue
      })
      if (selectedLayer[0]) {
        selectedLayer[0].select()
      }
    }
  }

  renderContent() {
    const controller = this
    $('#frms110-content').hide()
    this.map = Highmaps.mapChart(this.mapTarget, {
      title: {
        text: 'FMR and SAFMR Map',
      },
      legend: {
        title: {
          text: 'FMR in Dollars',
        },
      },
      caption: {
        text: this.captionTextValue,
        align: 'center',
      },
      mapView: {
        projection: {
          name: 'WebMercator',
        },
      },
      mapNavigation: {
        enabled: this.navValue,
        buttonOptions: {
          verticalAlign: 'top',
        },
        enableMouseWheelZoom: false,
      },
      plotOptions: {
        map: {
          borderColor: '#fff',
        },
      },
      series: this.series,
      tooltip: {
        colorAxis: false,
        backgroundColor: '#fff',
        formatter: function () { return controller.formatterFunction(this.point) }
      },
      colorAxis: {
        labels: {
          format: "${value:0f}",
        },
        enabled: true,
        min: Math.min(
          ...this.safmrFilledMapDataValue.map((v) => v.value).filter((v) => v > 0),
          ...this.fmrFilledMapDataValue.map((v) => v.value).filter((v) => v > 0),
        ),
        startOnTick: false,
      },
    })

    this.highlightSelectedValue('SAFMR')
    this.highlightSelectedValue('FMR')
  }
}
